<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ setorEconomico.id ? 'Atualizar Setor Econômico' : 'Novo Setor Econômico' }}</h5>
                <div class="field col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="setorEconomico.title" />
                </div>
                <div class="field col-9">
                    <label class="required">Setor Sas</label>
                    <Dropdown v-model="setorEconomico.codigoSas" :options="setoresSas" optionValue="CodSetor" optionLabel="DescSetor" showClear />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="setorEconomico.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button label="Salvar" @click.stop="inserir" icon="pi pi-check-circle" :disabled="!setorEconomico.title || !setorEconomico.codigoSas"></Button>
        </div>
    </div>
</template>
<script>
import Services from './service';

export default {
    data() {
        return {
            setorEconomico: {
                active: true,
                codigoSas: null,
                descricaoSas: null,
                title: null,
            },
            setoresSas: [],
        };
    },
    mounted() {
        if (this.$route.params.id) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.setorEconomico = response.data;
                }
            });
        }
        this.obterSetorSas();
    },

    methods: {
        inserir() {
            this.$store.dispatch('addRequest');

            if (this.setorEconomico.id) {
                Services.atualizar(this.setorEconomico.id, this.setorEconomico).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                Services.inserir(this.setorEconomico).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Setor Econômico',
                    detail: `Setor Econômico ${edicao ? 'editado' : 'inserido'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        obterSetorSas() {
            this.$store.dispatch('addRequest');
            Services.obterSetorSas().then((response) => {
                if (response?.success) {
                    this.setoresSas = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: response.errors[0],
                        life: 10000,
                    });
                    let setorDto = {
                        CodSetor: this.setorEconomico.codigoSas,
                        DescSetor: this.setorEconomico.descricaoSas,
                    };
                    this.setoresSas.push(setorDto);
                }
                this.$store.dispatch('removeRequest');
            });
        },
        cancelar() {
            this.$router.push({
                name: `SetorEconomico`,
            });
        },
    },
    watch: {
        'setorEconomico.codigoSas'() {
            if (this.setorEconomico.codigoSas > 0 && this.setoresSas.length > 0) {
                const result = this.setoresSas.filter((setor) => setor.CodSetor == this.setorEconomico.codigoSas);
                this.setorEconomico.descricaoSas = result[0].DescSetor;
                this.setorEconomico.codigoSas = result[0].CodSetor;
            }
        },
    },
};
</script>
